import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import new_logo from '../assets/logo/forwebsite.png';

// Styled components for header elements
const HeaderContainer = styled.header`
  background-color: #222; /* Blackish background */
  color: white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const TitleDiv = styled.div`
  /* Add any specific styles for the title */
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Navigation = styled.nav`
  display: inline-block;
  
`;

const NavigationList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  gap: 1.5rem;

`;

const NavigationItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const NavLink = styled(Link)`
  color: var(--c-white);
  transition: all 0.4s ease-in-out;
  text-decoration: none;


  &:hover {
    color: var(--c-yellow);
  };
`;

const HeaderImg = styled.img`
  align-self: center;
  width: 100%;
  height: auto;
  }
`;

const Linker = styled(Link)`
  text-decoration:none;
  color: white;
`;

const Header = () => {

  return (
    <HeaderContainer>

      
      <TitleDiv id="textPartArea">
        <Linker to='/'>
        <HeaderImg src={new_logo}/>
        {/* <HeaderTxt>Samplications</HeaderTxt> */}
        </Linker>
        
      </TitleDiv>
      <Navigation>
        <NavigationList>
          <NavigationItem>
            <NavLink to="/">Home</NavLink>
          </NavigationItem>
          <NavigationItem>
            <NavLink to="/Services">Services</NavLink>
          </NavigationItem>
          <NavigationItem>
            <NavLink to="/Contact">Contact</NavLink>
          </NavigationItem>
        </NavigationList>
      </Navigation>
    </HeaderContainer>
  );
};

export default Header
